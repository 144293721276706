import { USStateCode } from 'utils/stateNames';

interface UtilityProvider {
  name: string;
  providerId: string;
}

const PERMITTED_UTILITY_PROVIDERS_MAP: Partial<Record<USStateCode, UtilityProvider[]>> = Object.freeze({
  VA: [{ name: 'Appalachian Power (AEP Appalachian)', providerId: 'prv_1e8283e3-a32a-d187-b337-0a283f4b8548' }],
  AZ: [
    { name: 'Arizona Public Service (APS)', providerId: 'prv_36d92df7-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Salt River Project (SRP)', providerId: 'prv_36eba4c6-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Tucson Electric', providerId: 'prv_36ecdc72-ea0a-11e0-b6bb-12313d2b6294' },
  ],
  NJ: [{ name: 'Atlantic City Electric (ACE)', providerId: 'prv_36d9550b-ea0a-11e0-b6bb-12313d2b6294' }],
  MD: [
    { name: 'Baltimore Gas & Electric (BGE)', providerId: 'prv_36d9a332-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Potomac Electric Power Co (PEPCO)', providerId: 'prv_5c01f071-243e-11e3-a178-22000aa40d1f' },
  ],
  IL: [{ name: 'Commonwealth Edison (ComEd)', providerId: 'prv_36dc3aa3-ea0a-11e0-b6bb-12313d2b6294' }],
  MI: [
    { name: 'Consumers Energy', providerId: 'prv_36dc88c9-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'DTE Electric', providerId: 'prv_36dcafe0-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' },
  ],
  IA: [{ name: 'Consumers Energy', providerId: 'prv_36dc88c9-ea0a-11e0-b6bb-12313d2b6294' }],
  FL: [{ name: 'Florida Power & Light (FPL)', providerId: 'prv_36dd734d-ea0a-11e0-b6bb-12313d2b6294' }],
  GA: [{ name: 'Georgia Power', providerId: 'prv_36dde88a-ea0a-11e0-b6bb-12313d2b6294' }],
  OH: [{ name: 'Ohio Power (AEP Ohio)', providerId: 'prv_36d906e0-ea0a-11e0-b6bb-12313d2b6294' }],
  LA: [{ name: 'Entergy', providerId: 'prv_36dd734a-ea0a-11e0-b6bb-12313d2b6294' }],
  AR: [{ name: 'Entergy', providerId: 'prv_36dd734a-ea0a-11e0-b6bb-12313d2b6294' }],
  MS: [{ name: 'Entergy', providerId: 'prv_36dd734a-ea0a-11e0-b6bb-12313d2b6294' }],
  TN: [{ name: 'Entergy', providerId: 'prv_36dd734a-ea0a-11e0-b6bb-12313d2b6294' }],
  KS: [{ name: 'Evergy', providerId: 'prv_1e9fa6b2-bb6d-ddb4-b915-0e95e51793c4' }],
  MO: [{ name: 'Evergy', providerId: 'prv_1e9fa6b2-bb6d-ddb4-b915-0e95e51793c4' }],
  CA: [
    // { name: 'Pacific Gas & Electric (PG&E)', providerId: 'prv_36e14462-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Pacific Power', providerId: 'prv_36e14463-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Sacramento Municipal Util Dist (SMUD)', providerId: 'prv_36eb7db3-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'San Diego Gas & Electric Co (SDG&E)', providerId: 'prv_36eb7db1-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Southern California Edison Co (SCE)', providerId: 'prv_36eb7db0-ea0a-11e0-b6bb-12313d2b6294' },
  ],
  OR: [{ name: 'Pacific Power', providerId: 'prv_36e14463-ea0a-11e0-b6bb-12313d2b6294' }],
  WA: [
    { name: 'Pacific Power', providerId: 'prv_36e14463-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'Puget Sound Energy (PSE)', providerId: 'prv_36e1e0b4-ea0a-11e0-b6bb-12313d2b6294' },
  ],
  PA: [
    { name: 'PECO Energy', providerId: 'prv_36e14460-ea0a-11e0-b6bb-12313d2b6294' },
    { name: 'PPL Electric Utilities', providerId: 'prv_36e14461-ea0a-11e0-b6bb-12313d2b6294' },
  ],
  OK: [
    {
      name: 'Public Service Co of Oklahoma (AEP Oklahoma)',
      providerId: 'prv_36d906e1-ea0a-11e0-b6bb-12313d2b6294',
    },
  ],
  UT: [{ name: 'Rocky Mountain Power', providerId: 'prv_36eb7daf-ea0a-11e0-b6bb-12313d2b6294' }],
  ID: [{ name: 'Rocky Mountain Power', providerId: 'prv_36eb7daf-ea0a-11e0-b6bb-12313d2b6294' }],
  WY: [{ name: 'Rocky Mountain Power', providerId: 'prv_36eb7daf-ea0a-11e0-b6bb-12313d2b6294' }],
  WI: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
  CO: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
  MN: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
  NM: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
  ND: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
  SD: [{ name: 'Xcel Energy', providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294' }],
});

export default PERMITTED_UTILITY_PROVIDERS_MAP;
