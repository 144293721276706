import { ToastContainer } from '@palmetto/palmetto-components';
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';

import { UserSource } from 'api/user/types';
import { useUser } from 'api/user/useUser';
import IntercomBubble from 'components/IntercomBubble/IntercomBubble';
import SmartBannerAndroid from 'components/SmartBannerAndroid/SmartBannerAndroid';
import { PlatformConfig } from 'config';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { getDimensions } from 'utils/getDimensions';

// eslint-disable-next-line css-modules/no-unused-class
import { currentPageContext } from 'components/_app/CurrentPageProvider/currentPageContext';
import { useLocation } from 'react-router-dom';
import styles from './Page.module.scss';
import Content from './components/Content';

const DISABLE_IONIC_PAGE_POSITIONING = PlatformConfig.isChromatic; // used for Chromatic snapshots

type PageLayout = 'grid' | 'singleItem' | 'blank';
export interface Props {
  className?: string;
  disableIntercomPageBottomPadding?: boolean;
  layout: PageLayout;
  headingLine1?: string;
  headingLine2?: string;
  children: React.ReactNode;
  intercomVerticalPadding?: number;
  stickyFooter?: React.ReactNode;
  title?: string;
}

const Page: React.FC<Props> = (props: Props) => {
  const {
    className,
    layout,
    headingLine1,
    headingLine2,
    children,
    disableIntercomPageBottomPadding = false,
    intercomVerticalPadding,
    stickyFooter,
    title,
  } = props;

  const contentPageRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const { user } = useUser();
  const disableIntercom = Boolean(stickyFooter) || user?.source === UserSource.epc;
  const { setPageTitle } = useContext(currentPageContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (title) {
      setPageTitle(title);
    } else {
      setPageTitle('');
    }
  }, [title, setPageTitle, pathname]);

  useResizeObserver(() => {
    if (contentPageRef.current) {
      const { contentWidth } = getDimensions(contentPageRef.current);
      if (!Number.isNaN(contentWidth)) {
        document
          .querySelector<HTMLDivElement>('.app')
          ?.style.setProperty('--measured-content-page-width', `${contentWidth}px`);
      }
    }
    if (headingRef.current) {
      const { height } = getDimensions(headingRef.current);
      if (!Number.isNaN(height)) {
        headingRef.current.style.setProperty('--height', `${height}`);
      }
    }
  }, 100);

  const onScroll = () => {
    document
      .querySelector<HTMLDivElement>('.app')
      ?.style.setProperty('--measured-content-page-scroll', `${contentRef.current?.scrollTop || 0}`);
  };
  onScroll(); // update scroll position on render

  return (
    <>
      <SmartBannerAndroid />
      <IntercomBubble disabled={disableIntercom} user={user} verticalPadding={intercomVerticalPadding} />
      <ToastContainer position="bottom-center" />
      <Content
        ref={contentRef}
        onScroll={onScroll}
        className={classNames(styles.content, {
          [styles.staticContent]: DISABLE_IONIC_PAGE_POSITIONING,
          [styles.intercomEnabled]: !disableIntercom && !disableIntercomPageBottomPadding,
        })}
        staticContent={DISABLE_IONIC_PAGE_POSITIONING}
      >
        <div ref={contentPageRef} className={classNames(styles.contentContainer, styles[layout])}>
          {headingLine1 && (
            <h1 ref={headingRef} className={styles.heading}>
              {headingLine1}
              {headingLine2 && <br />}
              {headingLine2}
            </h1>
          )}
          <div className={classNames(styles.body, className, styles[layout])}>{children}</div>
        </div>
      </Content>
      {stickyFooter && <footer className={styles.stickyFooter}>{stickyFooter}</footer>}
    </>
  );
};

export default Page;
