import { lazy } from 'react';

import { FeatureConfig } from 'config';
import { ROUTE } from 'routes';

import { RouteConfig } from './types';

const HomeownersAssociation = lazy(() => import('pages/Onboarding/HomeownersAssociation/HomeownersAssociation'));
const IncomeVerification = lazy(() => import('pages/Onboarding/IncomeVerification/IncomeVerification'));
const SiteSurveyV2 = lazy(() => import('pages/Onboarding/SiteSurveyV2/SiteSurveyV2'));
const TitleVerification = lazy(() => import('pages/Onboarding/TitleVerification/TitleVerification'));
const UtilityBill = lazy(() => import('pages/UploadUtilityBill/UploadUtilityBill'));

export const ONBOARDING_FEED_ITEM_ROUTES: RouteConfig[] = [
  {
    path: ROUTE.ONBOARDING_SITE_SURVEY,
    exact: true,
    component: SiteSurveyV2,
    featureConfig: FeatureConfig.siteSurvey,
    isPublic: true,
    hideBackgroundImage: true,
    headerProps: {
      hideHeader: true,
    },
  },
  {
    path: ROUTE.ONBOARDING_ENERGY_BILL,
    exact: true,
    component: UtilityBill,
    headerProps: {
      pageTitle: 'onboarding.utilityBill.pageTitle',
    },
  },
  {
    path: ROUTE.ONBOARDING_HOMEOWNERS_ASSOCIATION,
    exact: true,
    component: HomeownersAssociation,
    headerProps: {
      pageTitle: 'onboarding.homeownersAssociation.pageTitle',
    },
  },
  {
    path: ROUTE.ONBOARDING_TITLE_VERIFICATION,
    exact: true,
    component: TitleVerification,
    headerProps: {
      pageTitle: 'onboarding.titleVerification.pageTitle',
    },
  },
  {
    path: ROUTE.ONBOARDING_INCOME_VERIFICATION,
    exact: true,
    component: IncomeVerification,
    headerProps: {
      pageTitle: 'onboarding.incomeVerification.pageTitle',
    },
  },
];
