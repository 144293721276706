import { Platforms } from '@ionic/core';
import { isPlatform as isIonicPlatform } from '@ionic/react';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { datadogRum } from '@datadog/browser-rum';
import { UserSource } from 'api/user/types';
import isRunningChromatic from 'chromatic/isChromatic';
import ImportedFeatureConfig from 'config/featureConfig';

export type Platform = 'web' | 'ios' | 'android';

type Environment = 'test' | 'dev' | 'next' | 'live';

// webpack5 no longer includes polyfill for destructuring multiple values from process.env
// see https://github.com/webpack/webpack/issues/14800
/* eslint-disable prefer-destructuring */
const NODE_ENV = process.env.NODE_ENV;
const VITE_API_HOST = process.env.VITE_API_HOST;
const VITE_FULLSTORY_ORG_ID = process.env.VITE_FULLSTORY_ORG_ID;
const VITE_FULLSTORY_DEV_MODE = process.env.VITE_FULLSTORY_DEV_MODE;
const VITE_STRIPE_API_KEY = process.env.VITE_STRIPE_API_KEY || process.env.STORYBOOK_STRIPE_API_KEY;
const VITE_STRIPE_API_SECRET_KEY =
  process.env.VITE_STRIPE_API_SECRET_KEY || process.env.STORYBOOK_STRIPE_API_SECRET_KEY;
const VITE_STRIPE_API_KEY_SABAL = process.env.VITE_STRIPE_API_KEY_SABAL;
const VITE_PALMETTO_WEB_URL = process.env.VITE_PALMETTO_WEB_URL;
const VITE_SEGMENT_API_KEY = process.env.VITE_SEGMENT_API_KEY;
const VITE_INTERCOM_APP_ID = process.env.VITE_INTERCOM_APP_ID;
const STORYBOOK = process.env.STORYBOOK;
/* eslint-enable prefer-destructuring */

const isPlatform = (platform: Platforms) => {
  if (!window.matchMedia) {
    return false;
  }
  return isIonicPlatform(platform);
};

const isNative = isPlatform('capacitor');
const isIOS = isPlatform('ios') || isPlatform('iphone') || isPlatform('ipad');
const isAndroid = isPlatform('android');

let platform: Platform;
let appId;
if (isIOS) {
  platform = 'ios';
  appId = 'com.palmetto.palmettodev';
} else if (isAndroid) {
  platform = 'android';
  appId = 'com.palmetto.home';
} else {
  platform = 'web';
}

const isProductionBuild = NODE_ENV === 'production';
const isTestRun = NODE_ENV === 'test';
const isStorybook = STORYBOOK === 'true';
const isChromatic = isRunningChromatic();

const baseApiUrl =
  VITE_API_HOST || (isProductionBuild ? 'https://home.palmetto.com' : 'https://home-next.palmetto.com');

const palmettoWebUrl =
  VITE_PALMETTO_WEB_URL || (isProductionBuild ? 'https://palmetto.com' : 'https://next.palmetto.com');

const { hostname } = new URL(baseApiUrl);

let environment: Environment;
if (isTestRun || isStorybook) {
  environment = 'test';
} else if (isProductionBuild && hostname === 'home.palmetto.com') {
  environment = 'live';
} else if (isProductionBuild && hostname === 'home-next.palmetto.com') {
  environment = 'next';
} else {
  environment = 'dev';
}

const isLive = environment === 'live';
const isNext = environment === 'next';
const isDev = environment === 'dev';

export enum LDFlagKeys {
  ambassador = 'ambassador',
  ambassadorW9 = 'ambassadorW9',
  dynamicTimelines = 'dynamicTimelines',
  energyBills = 'energyBills',
  acceptPanelPlacement = 'acceptPanelPlacement',
  notifications = 'notifications',
  stageGroupPredictions = 'stageGroupPredictions',
  activationDatePrediction = 'activationDatePrediction',
  sol = 'sol',
  solOrderSummary = 'solOrderSummary',
  pointOfContact = 'pointOfContact',
  financialsSection = 'financialsSection',
  referAFriend = 'referAFriend',
  lightReachWelcomeQuestionnaire = 'lightReachWelcomeQuestionnaire',
  palmettoProtectBillingMvp = 'palmettoProtectBillingMvp',
  servicePage = 'servicePage',
  rewardsTracker = 'rewardsTracker',
  energyAdvisorPage = 'energyAdvisorPage',
  energyAdvisorV2 = 'energyAdvisorV2',
  paymentsV3 = 'paymentsV3',
  paymentsV3FinanceAccount = 'paymentsV3FinanceAccount',
  energyProfile = 'energyProfile',
  utilityProvider = 'utilityProvider',
  utilityRatePlanActionItem = 'utilityRatePlanActionItem',
  bypassBankValidationErrorThreshold = 'bypassBankValidationErrorThreshold',
}

export type LDFlags = LDFlagSet & {
  [key in LDFlagKeys]?: boolean;
};

export const PlatformConfig: {
  platform: Platform; // web, ios, android
  environment: Environment; // test, dev, next, live
  isNative: boolean; // shortcut for ios or android as platform
  isIOS: boolean; // shortcut for ios as platform
  isAndroid: boolean; // shortcut for android as platform
  isProductionBuild: boolean; // is this a production build?
  isTestRun: boolean; // is this a test run?
  isStorybook: boolean; // is this running in Storybook?
  isChromatic: boolean; // is this running in Chromatic
  isLive: boolean; // is this running in the live environment?
  isNext: boolean; // is this running in the next environment?
  isDev: boolean; // is this running in the dev environment?
  appId?: string; // native app ID (e.g. com.palmetto.home)
  hostname: string; // expected web hostname (native hostname is always localhost)
  baseApiUrl: string; // the base API URL
  palmettoWebUrl: string; // the base API URL for palmetto.com
} = {
  platform,
  environment,
  isNative,
  isIOS,
  isAndroid,
  isProductionBuild,
  isTestRun,
  isStorybook,
  isChromatic,
  isLive,
  isNext,
  isDev,
  appId,
  hostname,
  baseApiUrl,
  palmettoWebUrl,
};

if (!isTestRun) {
  if (!VITE_STRIPE_API_KEY) {
    datadogRum.addError('VITE_STRIPE_API_KEY is missing');
  }
  if (!VITE_STRIPE_API_KEY_SABAL) {
    datadogRum.addError('VITE_STRIPE_API_KEY is missing');
  }
  if (!VITE_FULLSTORY_ORG_ID) {
    datadogRum.addError('VITE_FULLSTORY_ORG_ID is missing');
  }
}

export const FeatureConfig = ImportedFeatureConfig;

export const StripeConfig: {
  apiKey: string;
  apiSecretKey: string;
  apiKeySabal: string;
} = {
  apiKey: VITE_STRIPE_API_KEY || '',
  apiSecretKey: VITE_STRIPE_API_SECRET_KEY || '',
  apiKeySabal: VITE_STRIPE_API_KEY_SABAL || '',
};

export const FullStoryConfig = {
  orgId: VITE_FULLSTORY_ORG_ID || '',
  devMode: VITE_FULLSTORY_DEV_MODE === 'true',
};

export const SegmentConfig = {
  apiKey: VITE_SEGMENT_API_KEY || '',
};

export const SABAL_FINANCING_SOURCES = new Set([UserSource.palmettoPPA, UserSource.epc]);

export const intercomConfig = {
  appId: VITE_INTERCOM_APP_ID || '',
};
