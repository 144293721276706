import { Badge, Drawer, Icon, useBreakpoint } from '@palmetto/palmetto-components';
import useProperties from 'api/properties/useProperties';
import { useUser } from 'api/user/useUser';
import { ReactComponent as PowerOffSvg } from 'assets/font-awesome-5/power-off.svg';
import { ReactComponent as Avatar } from 'assets/navigation-menu/Avatar.svg';
import classnames from 'classnames';
import AppVersion from 'components/AppVersion/AppVersion';
import HeaderButton from 'components/Header/components/HeaderButton/HeaderButton';
import NotificationBadge from 'components/Header/components/NotificationBadge/NotificationBadge';
import Link from 'components/Link/Link';
import OptionButton, { Props as OptionButtonProps } from 'components/OptionButton/OptionButton';
import { useThemeContext } from 'components/_app/ThemeProvider/ThemeProvider';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import { FeatureConfig } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE } from 'routes';
import styles from './HamburgerMenu.module.scss';

const getTrackingId = (name: string) => `hamburger-menu-${name}`;

interface MenuItemProps extends Omit<OptionButtonProps, 'trackingId'> {
  name: string;
  from?: string;
}

const MenuItem = (props: MenuItemProps) => {
  const { to, name, children, from, className, ...restProps } = props;
  const trackingId = getTrackingId(name);
  return (
    <OptionButton
      {...restProps}
      className={classnames(styles.optionButton, className, {
        [styles.selected]: from && from === to,
      })}
      to={to}
      trackingId={trackingId}
    >
      {children}
    </OptionButton>
  );
};

export interface Props {
  align: 'left' | 'right' | 'none';
}

const HamburgerMenu: React.FC<Props> = (props: Props) => {
  const { align } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { themeContainerRef } = useThemeContext();
  const { user, isAuthenticated } = useUser();
  const flags = useFlags();
  const { isPhone } = useBreakpoint();
  const { pathname } = useLocation();
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const [showReferralBadge, setShowReferralBadge] = React.useState(false);
  const [showEnergyAdvisorBadge, setShowEnergyAdvisorBadge] = React.useState(false);
  const [showEnergyProfileBadge, setShowEnergyProfileBadge] = React.useState(false);
  const { t } = useTranslation();
  const localePrefix = 'hamburgerMenu';

  const { data: property } = useProperties(user?.propertyIds?.[0]);

  React.useEffect(() => {
    const shouldShowReferralBadge =
      user && FeatureConfig.referAFriend(user, flags) && user.settings && !user.settings.hasViewedReferAFriend;

    setShowReferralBadge(shouldShowReferralBadge || false);

    const shouldShowEnergyAdvisorBadge =
      user &&
      FeatureConfig.energyAdvisorPage(user, flags, property) &&
      user.settings &&
      !user.settings.hasViewedEnergyAdvisorPage;

    setShowEnergyAdvisorBadge(shouldShowEnergyAdvisorBadge || false);

    const shouldShowEnergyProfileBadge =
      user &&
      FeatureConfig.energyProfile(undefined, flags) &&
      user.settings &&
      !user.settings.hasViewedEnergyProfilePage;

    setShowEnergyProfileBadge(shouldShowEnergyProfileBadge || false);
  }, [user, flags, property]);

  const open = () => setIsOpen(true);
  const close = () => {
    drawerRef.current?.classList.add(styles.closing);
    setTimeout(() => {
      setIsOpen(false);
      drawerRef.current?.classList.remove(styles.closing);
    }, 200);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <HeaderButton
        className={styles.hamburgerButton}
        align={align}
        onClick={open}
        trackingId={getTrackingId('open')}
      >
        <div style={{ position: 'relative' }}>
          <div className={showReferralBadge || showEnergyAdvisorBadge ? styles.iconMask : ''}>
            <Icon name="menu" size="lg" title="navigation menu" />
          </div>
          {(showReferralBadge || showEnergyAdvisorBadge || showEnergyProfileBadge) && (
            <NotificationBadge variant="float" />
          )}
        </div>
      </HeaderButton>
      <Drawer
        ref={drawerRef}
        isOpen={isOpen}
        onDismiss={close}
        closeOnOverlayClick
        placement="left"
        containerRef={themeContainerRef}
        width={isPhone ? '100%' : '300px'}
        ariaLabel="navigation menu"
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={styles.menu} onClick={close}>
          <div className={styles.closeButtonContainer}>
            <HeaderButton align="none" trackingId={getTrackingId('close')}>
              <Icon name="remove-light" size="lg" title="close navigation menu" />
            </HeaderButton>
          </div>

          <OptionButton
            className={classnames(styles.optionButton, styles.profile)}
            to={ROUTE.PROFILE}
            trackingId={getTrackingId('profile')}
            hideChevron={!isPhone}
          >
            {user?.firstName?.length && (
              <img
                className={styles.profileAvatar}
                src={`https://i0.wp.com/cdn.auth0.com/avatars/${`${user.firstName.substring(
                  0,
                  1,
                )}${user.lastName.substring(0, 1)}`.toLocaleLowerCase()}.png`}
                alt="avatar"
              />
            )}
            {!user?.firstName?.length && (
              <div className={styles.defaultAvatar}>
                <Avatar title="avatar" />
              </div>
            )}
            <div className={styles.profileInfo}>
              <div className={styles.profileName}>
                {user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}`.trim() : user?.email}
              </div>
              <div className={styles.profileView}>{t(`${localePrefix}.viewProfile`)}</div>
            </div>
          </OptionButton>
          {FeatureConfig.dashboard(user) && (
            <MenuItem
              name="dashboard"
              from={pathname}
              to={pathname === ROUTE.DASHBOARD ? ROUTE.DASHBOARD : ROUTE.HOME}
              hideChevron={!isPhone}
            >
              {t(`${localePrefix}.menuItems.dashboard`)}
            </MenuItem>
          )}
          {FeatureConfig.energyAdvisorPage(user, flags, property) && (
            <MenuItem name="service" from={pathname} to={ROUTE.ENERGY_ADVISOR} hideChevron={!isPhone}>
              <span className={styles.statusItem}>
                {t(`${localePrefix}.menuItems.energyAdvisor`)}
                <Badge className={styles.badge} message="beta" variant="tertiary" size="sm" />
                {showEnergyAdvisorBadge && <NotificationBadge variant="inline" />}
              </span>
            </MenuItem>
          )}
          {FeatureConfig.payment(user) && (
            <MenuItem name="payment" from={pathname} to={ROUTE.FINANCE_ACCOUNTS} hideChevron={!isPhone}>
              {t(`${localePrefix}.menuItems.payments`)}
            </MenuItem>
          )}
          {FeatureConfig.referAFriend(user, flags) && (
            <MenuItem name="refer-a-friend" from={pathname} to={ROUTE.REFER} hideChevron={!isPhone}>
              <span className={styles.statusItem}>
                {t(`${localePrefix}.menuItems.referAFriend`)}
                {showReferralBadge && <NotificationBadge variant="inline" />}
              </span>
            </MenuItem>
          )}
          {FeatureConfig.service(user, flags) && (
            <MenuItem name="service" from={pathname} to={ROUTE.SERVICE} hideChevron={!isPhone}>
              <span className={styles.statusItem}>{t(`${localePrefix}.menuItems.service`)}</span>
            </MenuItem>
          )}
          {FeatureConfig.energyProfile(undefined, flags) && (
            <MenuItem name="energy-profile" from={pathname} to={ROUTE.ENERGY_PROFILE} hideChevron={!isPhone}>
              <span className={styles.statusItem}>
                {t(`${localePrefix}.menuItems.energyProfile`)}
                {showEnergyProfileBadge && <NotificationBadge variant="inline" />}
              </span>
            </MenuItem>
          )}
          <div className={styles.menuBottom}>
            <div className={styles.support}>
              {t('common.needHelp')}
              <br />
              <Link variant="standalone-gray" to={ROUTE.SUPPORT} trackingId={getTrackingId('support')}>
                {t('common.contactSupport')}
              </Link>
            </div>
            <AppVersion />
            {isAuthenticated && (
              <MenuItem
                className={styles.logout}
                name="logout"
                from={pathname}
                to={ROUTE.LOGOUT}
                actionSvg={PowerOffSvg}
              >
                {t(`${localePrefix}.logOut`)}
              </MenuItem>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
