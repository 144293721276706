import { ApiFinanceAccountUnionResponse } from 'api/payments/types';
import { useSubscriptions } from 'api/subscriptions/useSubscriptions';
import { FinancialAccountProduct, FinancialAccountSource } from 'api/user/types';
import { useUser } from 'api/user/useUser';
import LinksCard, { CardLink } from 'components/LinksCard/LinksCard';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import { SABAL_FINANCING_SOURCES } from 'config';

export interface PaymentLinksProps {
  className?: string;
  financeAccount?: ApiFinanceAccountUnionResponse;
}

const cardTrackingId = 'payment-links';

const PaymentLinks = (props: PaymentLinksProps) => {
  const { className, financeAccount } = props;
  const { user } = useUser();
  const {
    data: { subscription },
    isFetched: subscriptionsIsFetched,
  } = useSubscriptions();
  const { t } = useTranslation();
  const hasSubscription = Boolean(subscriptionsIsFetched && subscription?.category);
  const isPalmettoProtect = hasSubscription && subscription?.category === 'palmettoProtect';
  const isLightReachCustomer = user?.source && SABAL_FINANCING_SOURCES.has(user.source);
  const isUtilityRatePlan =
    financeAccount?.product === FinancialAccountProduct.utilityRatePlan &&
    financeAccount?.source === FinancialAccountSource.lightEnergy;

  if (!subscriptionsIsFetched) {
    return null;
  }

  let templateKey;

  if (isUtilityRatePlan) {
    templateKey = 'utilityRatePlan';
  } else if (isLightReachCustomer) {
    templateKey = 'lightReach';
  } else if (isPalmettoProtect) {
    templateKey = 'palmettoProtect';
  } else {
    templateKey = 'default';
  }
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const templateLinks = t(`payments.paymentLinks.${templateKey}`, {
    returnObjects: true,
  }) as CardLink[];

  if (isUtilityRatePlan) {
    templateLinks.forEach((link) => {
      if (!link.href) {
        link.href = financeAccount.documents?.[link.trackingId as 'efl' | 'tos' | 'yrac'] || '';
      }
    });
  }

  return (
    <LinksCard className={className} trackingId={cardTrackingId} links={templateLinks.filter((link) => link.href)} />
  );
};

export default PaymentLinks;
