import request from 'api';
import { RequestInterval } from 'api/energyAdvisor/types';
import { GridConsumptionApiResponse, Property, UpdateBuildingAttributes } from 'api/properties/types';
import { UtilityRatePlanBenchmarksResponse, RatePlanEligibilityResponse } from 'api/utilityRatePlan/types';

export const PROPERTIES_ENDPOINT = '/properties';

export const getProperty = async (propertyId: string): Promise<Property> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}` });
  return result.data;
};

export const getGridConsumption = async (interval: RequestInterval): Promise<GridConsumptionApiResponse> => {
  const result = await request({
    url: `${PROPERTIES_ENDPOINT}/grid-consumption`,
    params: interval,
  });
  return result.data;
};

export const updateBuildingAttributes = async ({
  propertyId,
  buildingAttributes,
}: UpdateBuildingAttributes): Promise<void> => {
  await request({
    method: 'PATCH',
    url: `${PROPERTIES_ENDPOINT}/${propertyId}/building`,
    data: { buildingAttributes },
  });
};

export const getUtilityRatePlanEligibility = async (propertyId: string): Promise<RatePlanEligibilityResponse> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}/rate-plan-eligibility` });
  return result.data;
};

export const getUtilityRatePlanBenchmarks = async (
  propertyId: string,
): Promise<UtilityRatePlanBenchmarksResponse> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}/rate-plan-benchmarks` });
  return result.data;
};
